// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Thumbs, Autoplay } from "swiper";

// configure Swiper to use modules
Swiper.use([Autoplay, Navigation, Pagination, Thumbs]);

const defaultSliderOptions = {
  slidesPerView: 1,
  spaceBetween: 8,
  autoplay: 2000,
  breakpoints: {
    1200: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    300: {
      slidesPerView: 1,
    },
  },
};

// const defaultSliders = document.querySelectorAll(".defaultSlider-js");
// if (defaultSliders) {
//   defaultSliders.forEach((slider) => {
//     if (slider.classList.contains("is-3")) {
//       new Swiper(slider.querySelector(".swiper-container"), {
//         slidesPerView: 1,
//         spaceBetween: 15,
//         speed: 500,
//         breakpoints: {
//           1170: {
//             slidesPerView: 3,
//           },
//           800: {
//             slidesPerView: 2,
//           },
//           575: {
//             slidesPerView: 1,
//           },
//         },
//         navigation: {
//           nextEl: slider.querySelector(".defaultSlider-js-next"),
//           prevEl: slider.querySelector(".defaultSlider-js-prev"),
//         },
//       });
//     } else {
//       new Swiper(slider.querySelector(".swiper-container"), {
//         ...defaultSliderOptions,
//         navigation: {
//           nextEl: slider.querySelector(".defaultSlider-js-next"),
//           prevEl: slider.querySelector(".defaultSlider-js-prev"),
//         },
//       });
//     }
//   });
// }

new Swiper("#explore", {
  loop: true,
  autoplay: {
    delay: 2000,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

new Swiper("#tweets", {
  loop: true,
  autoplay: {
    delay: 2000,
  },
  pagination: {
    el: "#tweets .swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    800: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
  },
});
